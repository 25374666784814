import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import PubSub from 'pubsub-js';
import Register from '../register/index.jsx';
import SimpleModal from '../modal/modal';
import LoginForm from '../account/loginForm';
import AccountManager from '../../managers/Account';

class HomeCountdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showLoginModal: false,
      isLoggedin: false,
      hasPreRegBonus: process.env.PRE_REGISTRATION_BONUS,
    };
  }

  componentDidMount() {}

  readMore = (e) => {
    const { onFaqButton } = this.props;
    e.preventDefault();
    onFaqButton();
  }

  getNotified = (e) => {
    e.preventDefault();
    this.setState({
      showModal: true,
    });
  }

  showLoginModal = () => {
    this.setState({
      showModal: false,
      showLoginModal: true,
    });
  }

  loginSuccess = async () => {
    const { hasPreRegBonus } = this.state;
    PubSub.publish('account_updated');
    // hhofPreRegExistingInfo
    if (hasPreRegBonus && localStorage.getItem(process.env.CREATOR_SUBDOMAIN + 'PreRegExistingInfo')) {
      // generate a free NFT token
      const nftToken = await AccountManager.generateFreeNft(AccountManager.getToken(), process.env.PRE_REGISTRATION_DROP_ID);
      if (nftToken && nftToken.token) {
        localStorage.setItem(process.env.CREATOR_SUBDOMAIN + 'FreeToken', nftToken.token);
        localStorage.removeItem(process.env.CREATOR_SUBDOMAIN + 'PreRegExistingInfo');
      }
    }
    // if (window.location.pathname.includes('register')) {
    //   window.location.pathname = '/drop/' + process.env.DROP_ID;
    // }
    toast.success('Log in success.');
    this.setState({
      showLoginModal: false,
    });
    window.location.href = '/account/collection';
  }

  loginFailed = (error) => {
    toast.error(error || 'Oops! Something went wrong. Try again later.');
  }

  render() {
    const { assetData, layoutStyle } = this.props;
    const { showModal, showLoginModal } = this.state;
    return (
      <div id="moreInfoElement" className={layoutStyle === 'offset' ? 'featured-drop__outer -offset' : 'featured-drop__outer'}>
        <SimpleModal isOpen={showLoginModal} onBeforeClose={() => this.setState({ showLoginModal: false })}>
          <LoginForm
            onSuccess={() => this.loginSuccess()}
            onFail={(error) => this.loginFailed(error)}
            onRegisterButton={() => this.setState({ showLoginModal: false })} />
        </SimpleModal>
        <SimpleModal showLogo theme="branded" isOpen={showModal} onBeforeClose={() => this.setState({ showModal: false })}>
          <Register onLoginRequest={() => this.showLoginModal()} type="modal" />
        </SimpleModal>
        <div className="landing-featured-drop">
          <div className="featured-drop__row">
            
            <div className={layoutStyle === 'offset' ? 'featured-drop__col-image -offset' : 'featured-drop__col-image'}>
              {assetData.mediaMP4
                ? (
                  <video autoPlay="autoplay" playsinline loop muted>
                    <source src={assetData.mediaMP4} type="video/mp4" />
                    <track
                      default
                      kind="captions"
                      srcLang="en"
                      src="" />
                    Your browser does not support the video tag.
                  </video>
                )
                : <a href={assetData.mediaThumbnailUrl || assetData.mediaUrl} target="_blank" rel="noreferrer"><img src={assetData.mediaThumbnailUrl || assetData.mediaUrl} alt="ethos" /></a>}
            </div>

            <div className={layoutStyle === 'offset' ? 'featured-drop__col-text -offset' : 'featured-drop__col-text'}>
              <div className="featured-drop__vertical-center">
                <div
              className="featured-drop__title"
              dangerouslySetInnerHTML={{ __html: assetData.name }}>
                </div>
                <div className="featured-drop__description">
                  <div dangerouslySetInnerHTML={{ __html: assetData.description }}>
                  </div>
                  <div className="featured-drop__description -padding-bottom">{process.env.PURCHASE_INSTRUCTIONS}</div>
                </div>

                <div className="featured-drop__cta">
                  {/* <a
                className="button button-disabled">
                Pre-Registration Closed
              </a> */}
                  <a
                href="#"
                className="button"
                onClick={(e) => this.getNotified(e)}>
                    Pre-Register
                  </a>
                  <a
                href="#"
                className="button-text"
                onClick={(e) => this.readMore(e)}>
                    FAQ
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

HomeCountdown.propTypes = {
  assetData: PropTypes.objectOf(PropTypes.object),
  layoutStyle: PropTypes.string,
  onFaqButton: PropTypes.func,
};

export default HomeCountdown;
