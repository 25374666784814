import React, { Component } from 'react';
import { toast } from 'react-toastify';
import HomeHero from '../components/hero/homeHero';
import HomeDrop from '../components/featuredDrop/homeDrop';
import FeaturedDrop from '../components/featuredDrop/featuredDrop';
import FAQ from '../components/about/faq';
import SkullJewerlyFAQ from '../components/about/skulljewerlyFaq';
import CreatorManager from '../managers/Creator';
import FullWidthImage from '../components/hero/fullWidthImage';
import { getParameterByName } from '../managers/Helpers';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropId: process.env.DROP_ID,
      dropData: null,
    };
  }

  getDropData = async () => {
    const { dropId } = this.state;
    const dropData = await CreatorManager.getDrop(dropId);
    if (dropData) {
      this.setState({
        dropData,
      });
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (window.location.hash.includes('register=success')) {
      toast.success('Successfully created your account.');
      window.location.hash = '';
    }
    if (window.location.hash.includes('login=success')) {
      toast.success('Log in success.');
      window.location.hash = '';
    }
    if (getParameterByName('nftToken', window.location.href)) {
      // protec against duplicate tokens
      Object.entries(localStorage).map((obj) => {
        const key = obj[0];
        if (key.includes('NFT_TRANSFER')) {
          sessionStorage.removeItem(key);
        }
        return true;
      });
      // force the one in the URL to be in memory
      sessionStorage.setItem('NFT_TRANSFER_' + getParameterByName('nftToken', window.location.href), getParameterByName('nftToken', window.location.href));
      window.location.href = '/edrop/?nftToken=' + getParameterByName('nftToken', window.location.href);
    }
    await this.getDropData();
  }

  scrollToSection = (element) => {
    const scrollToElement = document.getElementById(element);
    scrollToElement.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const { dropData, transferToken } = this.state;
    return (
      <div>
        {dropData
        && (
        <div>
          <HomeHero
            data={dropData}
            type="landing"
            onLearnMore={() => this.scrollToSection('moreInfoElement')}
            onFaqButton={() => this.scrollToSection('homeFaq')} />
          <FeaturedDrop
            assetType="video"
            dropId={dropData.drop.dropId} />
          {dropData.asset2
            && (
            <HomeDrop
              layoutStyle="offset"
              dropData={dropData.drop2}
              assetData={dropData.asset2} />
            )}
          <FullWidthImage />
          {process.env.CREATOR_SUBDOMAIN === 'skulljewelry'
            ? <SkullJewerlyFAQ />
            : <FAQ />}
        </div>
        )}
      </div>
    );
  }
}

export default Home;
